<script lang="ts">
  import { onMount } from "svelte"
  import { LoadingOverlay } from "/@/control"
  import {
    isCurrentSessionValid,
    getAndClearLoginSuccessLocation,
    redirectWithPath,
    LOGIN_PAGE,
    setupAdminMode,
    APP_PAGE,
    initializeSession,
    LAST_LOCATION_KEY,
    IG_APP,
    redirectToMyIg,
  } from "/@lib/shared"

  const TASTYWORKS_TOKEN = "tastyworks-token"
  const TASTYWORKS_ACCOUNT = "tastyworks-account"

  function isSupportedBrowser() {
    return typeof ResizeObserver === "function"
  }

  function storeRedirect() {
    // Handle external redirects that don't contain a hash
    //   (those are handled in requireValidSession())
    let redirectUrl =
      "#" + window.location.href.substring(window.location.origin.length)
    sessionStorage.setItem(LAST_LOCATION_KEY, redirectUrl)
  }

  async function determineRedirect() {
    if (!isSupportedBrowser()) {
      return "/unsupported-browser.html"
    }

    const urlParams = new URLSearchParams(window.location.search)
    let isSessionInitialized = false

    if (urlParams.has(TASTYWORKS_TOKEN)) {
      const tastyworksToken = urlParams.get(TASTYWORKS_TOKEN)
      if (urlParams.has(TASTYWORKS_ACCOUNT)) {
        setupAdminMode(tastyworksToken, urlParams.get(TASTYWORKS_ACCOUNT))
      } else {
        initializeSession(tastyworksToken)
      }
      isSessionInitialized = true
    }

    const valid = await isCurrentSessionValid()

    if (!valid) {
      storeRedirect()
      return LOGIN_PAGE
    }

    return isSessionInitialized
      ? `${APP_PAGE}${window.location.hash}`
      : getAndClearLoginSuccessLocation()
  }

  onMount(async () => {
    try {
      const redirect = await determineRedirect()
      if (IG_APP && redirect === LOGIN_PAGE) {
        redirectToMyIg()
      } else {
        redirectWithPath(redirect)
      }
    } catch (e) {
      console.log("Failed to determine redirect", e)
      if (IG_APP) {
        redirectToMyIg()
      } else {
        redirectWithPath(LOGIN_PAGE)
      }
    }
  })
</script>

<LoadingOverlay />
